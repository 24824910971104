import {
	pink,
	purple,
	deepPurple,
	indigo,
	lightBlue,
	blue,
	cyan,
	teal,
	lightGreen,
	green,
	lime,
	yellow,
	amber,
	orange,
	deepOrange,
	red,
	brown,
	grey,
	blueGrey
} from '@mui/material/colors';

export const $primary = '#2196f3';
export const $secondary = '#151b26';
export const $danger = '#F46262';
export const $warning = '#F4A862';
export const $elevated = '#F4E562';
export const $success = '#36D18A';
export const $inactive = '#C4C4C4';

// ALERTS COLORS:
export const $laancStatusAuthorized = $success;
export const $laancStatusPending = $elevated;
export const $laancStatusRejected = $warning;
export const $laancStatusCanceled = $danger;

// LAANC COLOR:
export const $laancColor = '#6DBEEC';

// CUSTOM AIRSPACE COLORS:
export const $customAirspaceFillColor = 'rgba(250, 149, 25, 0.5)';
export const $customAirspaceLineColor = 'rgba(249, 160, 6, 0.75)';

export const $black = '#040D20';
export const $white = '#fff';

// BACKGROUND COLORS:
// Tan
export const $tan = '#C6C7C4';
// Dark Bluish Gray 7%
export const $gray100 = '#FAFAFA';
// Dark Bluish Gray 15%
export const $gray200 = '#EEF0F3';

// TEXT COLORS:
// Dark Bluish Gray
export const $gray300 = '#9096A3';
// Black 75%
export const $gray400 = '#434958';
// Dark Bluish Gray
export const $gray500 = '#9096A3';

// Old colors
export const $gray600 = '#6c757d';
export const $gray700 = '#495057';

export const $gray900 = '#212529';

// BUTTON COLORS:
export const $btnBlack = '#2A3142';
export const $btnPrimary = '#1489F5';
export const $btnPrimaryHover = '#0577E3';
export const $btnPrimaryDisabled = '#A1CFF3';

// random colors used in site.
export const $bluishGray60 = '#BBC2CF';

export const $darkBluishGray = '#8E9AAF';
export const $lightGray = '#E4E7EA';
export const $darkGray = '#555B6E';
export const $backgroundOffWhite = $gray100;

//Login
export const $loginLightGray = '#BDBDBD';
export const $loginDarkGray = '#828282';

const colorObjects = {
	// Blues group
	blue, // Primary blue
	cyan, // Lighter blue
	teal, // Blue-green

	// Reds group
	red, // Primary red
	pink, // Light red
	deepOrange, // Dark orange-red

	// Greens group
	green, // Primary green
	lime, // Light green

	// Purples group
	purple, // Primary purple

	// Pure colors
	yellow, // Pure yellow

	// Neutrals last
	brown, // Warm neutral
	blueGrey // Cool neutral
};

// Remove grey since it's too close to blueGrey
// Define shades in order of preference
const shades = [500, 700, 300, 900];

// Generate colors array dynamically based on needed length
export const getChartColors = neededLength => {
	const colors = [];
	const baseColors = Object.values(colorObjects);

	// First use all base colors at 500 shade
	colors.push(...baseColors.map(color => color[500]));

	// If we need more colors, start adding additional shades
	if (neededLength > baseColors.length) {
		// For each additional shade
		for (
			let shadeIndex = 1;
			shadeIndex < shades.length && colors.length < neededLength;
			shadeIndex++
		) {
			const currentShade = shades[shadeIndex];
			// Add this shade for each base color
			colors.push(...baseColors.map(color => color[currentShade]));
		}
	}

	return colors.slice(0, neededLength);
};

// For backward compatibility, export muiColors with a default reasonable size
export const muiColors = getChartColors(18); // or whatever default size you want

//Clustering colors (from MUI purple theme)
export const $clusterSmall = '#ce93d8'; //MUI 200
export const $clusterMedium = '#ab47bc'; //MUI 400
export const $clusterLarge = '#8e24aa'; //MUI 600

export const $groundBrown = '#8a4c12';
export const $flightPathPink = '#ed6498';

//Traffic

export const $lowAltitudeColor = $danger;
export const $midAltitudeColor = $elevated;
export const $highAltitudeColor = $success;

export { colorObjects };
